import styled from '@emotion/styled';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { EnHeading } from 'components/atoms/enHeading';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import { HasTabHeading } from 'components/organisms/hasTabHeading';
import { TopicsGroup } from 'components/organisms/topicsGroup';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import { ViewMoreWrapper } from 'components/molecules/viewMoreWrapper';
import { TopicsCard } from 'components/molecules/topicsCard';
import { TopicsIndexEnQuery } from 'types/graphql-types';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import { mq, breakpoint } from 'style/variables';
import sizes from 'style/sizes';
import FadeUpSection from 'components/organisms/fadeUpSection';
import { contentWrapper } from 'style/mixin';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: TopicsIndexEnQuery;
}

const categories = ['ALL', 'NEWS', 'ARTICLES', 'EVENTS'] as const;
type CategoryType = typeof categories[number];

const TitleSection = styled('div')`
  width: 100%;
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    width: auto;
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
    padding-left: ${sizes.margin[32]};
  }
`;

const TitleArea = styled('div')`
  ${contentWrapper}
  ${mq.onlypc} {
    padding: 0;
  }
  ${mq.onlysp} {
    padding: 0;
  }
`;

const tabs = categories.map(category => {
  return {
    text: category,
    value: category,
  };
});

const initailShowCount = 12;
const upCount = 12;

const Pages: React.FC<IndexPageProps> = ({ data }) => {
  const [category, setCategory] = useState<CategoryType>('ALL');
  const [showCount, setShowCount] = useState(initailShowCount);
  const [increment, setIncrement] = useState(0);
  const topics = data.allShifterTopicsEnData.edges;

  const moreContent = useCallback(() => {
    setIncrement(increment => increment + 1);
    setShowCount(prevCount => prevCount + upCount);
  }, []);

  const handleCategory = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIncrement(0);
    setShowCount(initailShowCount);
    setCategory(e.currentTarget.textContent as CategoryType);
  }, []);

  const selectedTopics = useMemo(() => {
    return topics?.filter(topics => (category === 'ALL' ? true : topics.node?.acf?.category === category));
  }, [category]);

  const showTopics = selectedTopics?.slice(0, showCount);

  let isSp = false;
  if (typeof window !== 'undefined') isSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Topics'} en />
      <TitleArea>
        <TitleSection>
          <EnHeading>Topics</EnHeading>
        </TitleSection>
        <HasTabHeading tabs={tabs} callback={handleCategory} />
      </TitleArea>

      <ContentWrapper>
        <FadeUpSection>
          <TopicsGroup>
            {showTopics?.map((topics, idx) => {
              return (
                <TopicsCard
                  key={`topics-${category}-${topics.node.id}-${idx}`}
                  title={topics?.node.title?.rendered || 'No TITLE'}
                  date={dayjs(topics?.node.acf?.launch || undefined).format('YYYY.MM.DD')}
                  to={`/en/topics/${topics?.node.slug}` || ''}
                  imgpath={topics?.node.acf?.thumb ? topics?.node.acf?.thumb : 'noimage/img-card-thumb.jpg'}
                  category={topics?.node.acf?.category || ''}
                  delay={idx + 1 - increment * upCount}
                  isEn
                />
              );
            })}
          </TopicsGroup>

          {selectedTopics && selectedTopics.length > showCount && <ViewMoreWrapper onClick={moreContent} />}
        </FadeUpSection>
      </ContentWrapper>
      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query TopicsIndexEn {
    site {
      siteMetadata {
        title
      }
    }
    allShifterTopicsEnData(
      sort: { fields: acf___launch, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            launch
            category
            thumb
          }
          slug
        }
      }
    }
  }
`;
