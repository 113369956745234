import styled from '@emotion/styled';
import { TextButton } from 'components/atoms/textButton';
import React from 'react';
import sizes from 'style/sizes';
import { mq } from 'style/variables';

type ViewMoreWrapperProps = {
  onClick?: () => void;
  spInner?: boolean;
};

const Container = styled.button<ViewMoreWrapperProps>`
  position: relative;
  width: 100%;
  text-align: center;
  cursor: pointer;
  ${mq.onlysp} {
    padding-top: ${sizes.margin[40]};
  }
`;

export const ViewMoreWrapper: React.FC<ViewMoreWrapperProps> = ({ onClick, ...props }) => {
  return (
    <Container {...props} onClick={onClick}>
      <TextButton isBlock icon={'plus'}>
        View More
      </TextButton>
    </Container>
  );
};
